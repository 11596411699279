<template>
<NavBar @RoutePage="this.scrollToElement"/>
<!-- <NavBar /> -->
<section>
  <poster Title="מגוון רחב של עוגות ועוגיות טעימות וביתיות"/>

  <div class="container-allStroy">
    <div class="story-container">
      <StoryCircel v-for="Story in Storys"  :key="Story" 
      :StoryImg="Story.StoryImg" :titleStory="Story.titleStory" :idStory="Story.idStory"
      @StoryOpen="getdata($event)"
      />
    </div> 
  </div>
</section>

<section ref="AboutMe" id="AboutMe">
  <div class="container AboutMe">
      <img src="../assets/photo/shira-Portfolio.png" alt="">
      <div class="InfoDiv">
        <h1>קצת עליי</h1>
        <p>
          אמא של יהונתן ונויה 👦🏻👧🏻
          מעצבת עוגות ומגשי אירוח והבעלים @noyush_byshira
          Noyush by Shira Israel | עוגות | מארזים | מגשי אירוח חלבי
          ממתיקה לכם רגעים מאז שנת 2019 🍰
          חומרי הגלם הטובים והאיכותיים ביותר בשוק
          📦 משלוחים ואיסוף עצמי דימונה-ירוחם-באר שבע
        </p>
      </div>
  </div>
</section>

<section ref="Features" id="Features">
  <div class="Features">
    <h1>השירותים שלנו</h1>
    <div class="container Feature-Grid">
      <div class="Feature-Card" v-for="Feature in this.Features" :key="Feature">
        <div class="Logo"><i :class='Feature.logo'></i></div>
        <h6>{{ Feature.info }}</h6>
      </div>
    </div>
    
  </div>
</section>

<section id="ContactUs" ref='ContactUs'>
  <div class="container ContactUs">
    <div class="contact-card">
        <h2>כתבו לנו</h2>
        <p>נשמח לענות לכל שאלה , אנא השאר/י פרטים</p>
        <form action="#" method="post">
            <label for="name">שם מלא</label>
            <input type="text" id="name" name="name" required>

            <label for="email">נייד</label>
            <input type="text" id="tel" name="tel" required>

            <label for="email">אימייל</label>
            <input type="email" id="email" name="email" required>

            <label for="message">הודעה</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">שליחה</button>
        </form>
    </div>
    <div class="Info-Contact">
      <div>
        <h2>יצירת קשר</h2>
        <div class="ContactData">
          <span><i class="bi bi-phone"></i><span class='data-contact'>054-815-3153</span></span>
          <span><i class="bi bi-geo"></i><span class='data-contact'>דימונה</span></span>
          <span><i class="bi bi-envelope"></i><span class='data-contact'>Shirabz1803@walla.com</span></span>
        </div>
        <p>
        {{ this.ContactPragrafh }} 
        </p>
        <div class="Social-Card">
          <div class="Social" v-for="Social in this.SocialMedia" @click="this.SocialRoute(Social.Route)"  :key="Social">
            <i :class='Social.logo'></i>
          </div>
        </div>
      </div>
    </div>    
  </div>
</section>


<!-- <Footer/> -->
<section>
  <Footerx/>

</section>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import StoryCircel from '@/components/StoryCircel.vue'
import Footerx from '@/components/Footer.vue'
import poster from '@/components/posterPic.vue'
import axios from 'axios'


export default {
  name: 'Home',
  components: {
    NavBar,
    Footer,
    StoryCircel,
    Footerx,
    poster
},
  data(){
      return{
        ContactPragrafh:`
             אנחנו עושים משלוחים לאזורים ליד דימונה כגון : ירוחם , באר שבע
             משלוח בעיר: 20 שקל
             משלוח מחוץ לעיר: 50 שקל

`,
        cakes:[],
        Storys:[
          {StoryImg:"test1.jpeg",titleStory:"יום הולדת",idStory:1,StoryVideo:'test1.mp4'},
          {StoryImg:"test2.jpeg",titleStory:"עוגת שוקולד ",idStory:2,StoryVideo:'test2.mp4'},
          {StoryImg:"test3.jpeg",titleStory:"עוגת וניל ",idStory:3,StoryVideo:'test3.mp4'},
          {StoryImg:"test4.jpeg",titleStory:"עוגת אוראו ",idStory:4,StoryVideo:'test4.mp4'},
          {StoryImg:"test5.jpeg",titleStory:"עוגת חמאת בוטנים ",idStory:5,StoryVideo:'test5.mp4'},
          {StoryImg:"test3.jpeg",titleStory:"עוגת וניל ",idStory:3,StoryVideo:'test3.mp4'},
          {StoryImg:"test4.jpeg",titleStory:"עוגת אוראו ",idStory:4,StoryVideo:'test4.mp4'},
          {StoryImg:"test5.jpeg",titleStory:"עוגת חמאת בוטנים ",idStory:5,StoryVideo:'test5.mp4'},
          {StoryImg:"test3.jpeg",titleStory:"עוגת וניל ",idStory:3,StoryVideo:'test3.mp4'},
          {StoryImg:"test4.jpeg",titleStory:"עוגת אוראו ",idStory:4,StoryVideo:'test4.mp4'},
          {StoryImg:"test5.jpeg",titleStory:"עוגת חמאת בוטנים ",idStory:5,StoryVideo:'test5.mp4'},
        ],
        Features:[
          {logo:'bi bi-cash-coin' , info:'סכום לכל כיס'},
          {logo:'bi bi-hourglass-split' , info:'זמן המתנה נמוך'},
          {logo:'bi bi-award' , info:'אמינות ומקצועיות'},
        ],
        SocialMedia:[
        {logo:'bi bi-facebook' ,Route:'https://facebook.com'},
        {logo:'bi bi-instagram',Route:'https://instagram.com'},
        {logo:'bi bi-whatsapp',Route:'https://whatsapp.com'},
        ]
        
      }
      
  },
  created(){
  },
  computed: {

  },
  async mounted(){
   
  },
  methods: {
    scrollToElement(data) {
      this.$nextTick(() =>{
        console.log('Received event with data:', data);
        const element = this.$refs[data.router];
        console.log(element)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      })
      
    },
    SocialRoute(route){
      window.location.assign(route);
    }
  },
 
}
</script>

<style scoped>

/* ========== Story Section ========= */

.container-allStroy{
  border-bottom: 1px solid #ccc09c;
  overflow-x: auto;
  margin: auto;
  padding: 2rem;
  width: 90% !important;
  direction: rtl;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
}

.container-allStroy::-webkit-scrollbar{
  display: none;
}

.story-container{
  border: 1px solid none;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-auto-columns: 5rem;
  grid-gap: 2rem;
}

/* ========= AboutMe Section ============ */

.AboutMe{
  position: relative;
  padding: 25px 0px 50px 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  direction: rtl;
}

.AboutMe img{
  width: clamp(30px ,30vw,500px);
}

.InfoDiv{
  position: relative;
  border:1px solid none;
  width: 100%;
  max-width:500px;
  height: auto;
}

.InfoDiv p{
  font-size: 1.2em;
}

/* ========= Features section ========= */

.Features{
  width: 100%;
  background: #4E342E;
  color: #FFFFF0;
  text-align: center;
  padding: 50px;

}

.Feature-Grid{
  position: relative;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  gap: 20px;
}

.Feature-Card{
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FAF3E0;
  color: #4E342E;
  border-radius: .5rem;
  padding: 15px 15px 5px 15px;
  gap: 20px;
}

.Feature-Card .Logo{
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #4E342E;
  color: white;
}

.Feature-Card .Logo i{
  font-size: 2rem;
}


/* =========== ContactUs section ============= */

.textCss{
  text-align: center;
}

.ContactUs{
  direction: ltr;
  display: grid;
  grid-template-columns: repeat(2,minmax(0,1fr));
  gap: 20px;
  padding: 60px 0px;
}

.contact-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    /* width: 300px; */
    text-align: center;
    direction: rtl;
}

.contact-card h2 {
    color: #702c29; /* Deep burgundy color for the header */
}

.contact-card p {
    color: #702c29; /* Matching the text color with the header */
    margin: 0 0 15px;
}

.contact-card label {
    display: block;
    margin: 10px 0 5px;
    color: #702c29;
}

.contact-card input,
.contact-card textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.contact-card button {
    background-color: #702c29; /* Deep burgundy for the button */
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.contact-card button:hover {
    background-color: #5b1f1c; /* Slightly darker shade on hover */
    transition: ease 0.2s;
}

.Info-Contact{
  border: 1px solid none;
  display: flex;
  align-items: center;
  color: #702c29;
  text-align: right;
  direction: rtl;
  font-size: 1.1rem;
}

.Info-Contact p{
  white-space: pre-line;
  line-height: 30px;
}

.ContactData{
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 30px;
}


.data-contact{
  margin-right: 6px;
}

.Social-Card{
  border: 1px solid none;
  display: flex;
  gap: 0px 10px;
}

.Social{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #4E342E;
  color: white;
  cursor: pointer;
  transition: ease 0.2s;
}

.Social:hover{
  transform: translateY(-6px);
  box-shadow: 0 6px 2px -2px gray;
}

/* ============ Media Query Section ========== */
@media (max-width: 1000px) {
  .AboutMe{
    justify-content: space-around;
  }
  .InfoDiv{
    padding: 0px 20px 0px 0px;
  }
}

@media (max-width: 800px) {
  .story-container{
    justify-content: right;
    grid-auto-columns: 5.4rem;
  }
  .container-allStroy{
    border: 1px solid none;
    width: 100% !important;
    padding: 2rem 1rem 2rem 0;
  }

  .AboutMe img{
    width: 100%;
  }

  .InfoDiv{
    position: absolute;
    top:30%;
    right:50%;
    transform: translate(50%,50%);
    border:1px solid none;
    width: 75%;
    z-index:100;
    background-color: rgba(136, 165, 191, 0.677);
    padding: 10px;
    text-align: center;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    color: #fff;
  }

  .InfoDiv p{
    font-size: 1em;
  }
}

@media (max-width: 700px) {
  /* .InfoDiv{
    position: absolute;
    top:230px;
    right:50%;
    transform: translatex(50%);
    width: 80%;
  } */

  .InfoDiv p{
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .Feature-Grid{
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
  .ContactUs{
    grid-template-columns: repeat(1,minmax(0,1fr));
    padding: 20px;
    gap: 30px 0;
  }
}

@media (max-width: 390px) {
  .InfoDiv p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 5; 
    -webkit-box-orient: vertical;
  }
}



</style>




