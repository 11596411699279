<template>
<Navbar/>
<div class="UpgradeDiv">
  <div>
    <h1>עמוד '{{this.$route.params.name}}' אינו זמין בעקבות שיפוצים</h1>
    <br>
    <h6>בעוד {{ this.Count }} שניות תועבר לדף הבית</h6>
  </div>
</div>

</template>

<script>
import Navbar from '@/components/NavBar.vue'



export default {
  name: 'Upgrade',
  components: {
    Navbar,
  },
  props:[],
  data(){
    return{
      Count:5,
    }
  },
  mounted(){
    setTimeout(() =>{
      this.$router.push('/')
    },5000)
    setInterval(() =>{
      this.Count--
    },1000)
    // console.log(this.$route)
  },
  methods: {
  },
  
}

</script>

<style scoped>

.UpgradeDiv{
  border: 1px solid none;
  width: 100%;
  height: 50vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>




