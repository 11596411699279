<template>
  <nav class="navbar"> 
    <div class="brand-title"><img src="@/assets/Logo/Noyush.png" alt="" width="300px"></div>
    <!-- computer navbar -->
    <div class="NavRoutes" v-if="this.NavRoutes">
      <span class="RouteSpan" v-for="route in routes" :key="route">
        <router-link v-if="route.router != null && route.router.includes('/')" :ref="route.ref" style="text-decoration: none;width: 100%;height: 100%;" :to="route.router"><span class="HoverEffect">{{route.name}}</span></router-link>
        <span class="HoverEffect" v-else @click="this.RoutePage(route)">{{route.name}}</span>
      </span>
      <span class="RouteSpan">
        <router-link to="/cart">
          <span class="HoverEffect cartClass"><i class="bi bi-cart4"></i><span id="itemNum">{{ this.cartItems }}</span></span>
        </router-link>
      </span>
   
      
    </div>
    <!-- mobile navbar -->
    <a @click="OpenMenu" class="toggle-button">
      <span class="bar" id="first"></span>
      <span class="bar" id="two"></span>
      <span class="bar" id="three"></span>
    </a> 
    <a @click="this.ResizeMenu()" class="toggle-button ButttonResize">
      <span class="bar" id="first"></span>
      <span class="bar" id="two"></span>
      <span class="bar" id="three"></span>
    </a> 
    <div class="navbar-links">
      <ul>
        <li v-for="route in routes" :key="route">
          <router-link v-if="route.router != null && route.router.includes('/')"  style="text-decoration: none;" :to="route.router"><li><a>{{route.name}}</a></li></router-link>
          <li style="cursor: pointer;" v-else @click="this.RoutePage(route)"><a>{{route.name}}</a></li>
        </li>
        <router-link style="text-decoration: none;" to="/cart">
          <li><a><span style="position:relative"><i class="bi bi-cart4"></i> <span id="itemNum">{{ this.cartItems }}</span>   </span></a></li>
        </router-link>
        
      </ul>
    </div>
  </nav>
  
</template>

<script>

import axios from 'axios'
import { previousRoute } from '@/router';

export default {
  name: 'Navbar',
  data(){
    return{
      NavRoutes:true,
      NavRoutesExtra:false,
      cartItems:0, 
      routes:[
      {name:"בית",ref:"/",router:""},
      {name:"אודות",ref:"/",router:"AboutMe"},
      {name:"תפריט",ref:"",router:"/menu"},
      {name:"סדנאות",ref:"",router:"/WorkShops"},
      {name:"גלריה",ref:"",router:"/gallery"},
      {name:"יצירת קשר",ref:"/",router:"ContactUs"},
      ]
    }
  },
  props:{
  },
  created () {
    this.RefRoute()
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    window.addEventListener('beforeunload', this.ResetRouter);
    this.handleResize()
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('beforeunload', this.ResetRouter);
  },
  methods: {
    ResetRouter(event) {
      sessionStorage.setItem('ref', JSON.stringify({ ref: this.$route.fullPath, router: null }));
    },
    handleResize(){
      // console.log(window.innerWidth)
      if(window.innerWidth <= 800 ){
        document.getElementsByClassName('toggle-button')[0].style.display = 'flex';
      }else{
        document.getElementsByClassName('toggle-button')[0].style.display = 'none';
      }
    },
    handleScroll (event) {

      var navbar = document.getElementsByClassName('navbar')[0]
      navbar.classList.toggle("sticky",window.scrollY > 0)

      if(window.scrollY > 0 && this.NavRoutesExtra == true){
        this.NavRoutes = true
        document.getElementsByClassName('ButttonResize')[0].style.display = 'flex';
      }else if(window.scrollY > 0 && this.NavRoutesExtra == false){
        this.NavRoutes = false
        document.getElementsByClassName('ButttonResize')[0].style.display = 'flex';
      }else if(window.scrollY == 0 || window.innerWidth > 800){
        document.getElementsByClassName('ButttonResize')[0].style.display = 'none';
        this.NavRoutes = true
      }
    },
    ResizeMenu(){
      if(this.NavRoutesExtra == true){
        this.NavRoutesExtra = false
        this.NavRoutes = false
      }else{
        this.NavRoutesExtra = true
        this.NavRoutes = true
      }
      // console.log(this.NavRoutesExtra)
    },
    OpenMenu(event) {
      const toggleButton = document.getElementsByClassName('toggle-button')[0]
      const navbarLinks = document.getElementsByClassName('navbar-links')[0]
        
      navbarLinks.classList.toggle('active')
      toggleButton.classList.toggle('rotate')
    },
    RoutePage(data){
      // console.log(data)
      if(data.ref == '/' && data.router == ''){
        data.router = null
      }
      sessionStorage.setItem('ref',JSON.stringify({ref:data.ref,router:data.router}))
      if (this.$route.fullPath !== data.ref) {
        this.$router.push(data.ref)
      }else{
        this.RefRoute()
      }
      this.OpenMenu()
    },
    RefRoute(){
      const locationRef = JSON.parse(sessionStorage.getItem('ref'))
 
      if(locationRef){

        if(this.$route.fullPath == locationRef.ref){
          // console.log('emited')
          if(locationRef.router == null){
            // console.log('dsa')
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 0);
          }else{
            setTimeout(() => {
              this.$emit('RoutePage' , locationRef)
            }, 0);
          }
        }else{
          this.ResetRouter()
        }
      }else{
        this.ResetRouter()

      }
      
      
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{
  direction: ltr !important;
  box-sizing: border-box;
}

.navbar{
  /* top: 0 !important; */
  border: 1px solid none;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  transition: 0s ease-out;
  background:transparent;
  background-color: #fdf4da !important;

}

.NavRoutes{
  border-top: 1px solid #702c29;
  display: flex;
  direction: rtl !important;
  justify-content: space-evenly;
  width: 85%;
  /* height: 50px; */
  padding: 8px;
}
.NavRoutes span.RouteSpan{
  position: relative;
  height: fit-content;
  width: fit-content;
  padding: 12px 17px;
  transition: ease 0.2s;
  border-radius: 10px;
}

span.HoverEffect{
  position: relative;
  color: black;
  width: fit-content;
  height: fit-content;
  transition: ease 0.2s;
  padding: 12px 17px;
  cursor: pointer;
  border-radius: 10px;
}


span.HoverEffect:hover{
  background:#702c29;
  color:#faf3e0 !important;
}
.NavRoutes span.RouteSpan:hover{
  /* background:#702c29; */
  color:#faf3e0 !important;
}

.NavRoutes span.cartClass:hover ,#itemNum{
  border: 1px solid white;
}


.sticky{
  /* background-color: transparent; */
  box-shadow: rgba(0, 0, 0, 0.28) 0px 54px 55px, 
            rgba(0, 0, 0, 0.15) 0px -12px 30px, 
            rgba(0, 0, 0, 0.15) 0px 4px 6px, 
            rgba(0, 0, 0, 0.22) 0px 12px 13px, 
            rgba(0, 0, 0, 0.12) 0px -3px 5px,
            rgba(0, 0, 0, 0.12) 0px -3px 5px;
              transition: 0.3s ease;
}

.brand-title{
  max-width: 400px;
}

.brand-title img{
  width: 100%;
}

.navbar-links{
  display: none;
}


.toggle-button {
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
  cursor: pointer;
  transition:  0.2s ease-out;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: #702c29;
  border-radius: 10px;
  transition:  0.2s ease-out;
}


#itemNum{
  position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    background-color: #702c29;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: .7em;
    font-weight: 600;
}

@media (max-width: 800px) {

.brand-title{
  max-width: 250px;
}

.ButttonResize{
  display: none !important;
}

  #itemNum{
    position: absolute;
    top:-10px;
    right: -15px;
    font-size: .5em;
    /* left: 52%;
    bottom: 24%; */
    
  }

  .NavRoutes{
    display: none;
  }

  .navbar-links{
  margin-right: 2%;
  
}

.navbar-links ul{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row-reverse ;
}

.navbar-links li{
  list-style: none;
}

.navbar-links li a{
  text-decoration: none;
  color: #702c29;
  padding: 1rem;
  display: block;
  font-size: 1.3rem;
  transition: 0.2s ease-out;

}

.navbar-links li a:hover{
  background:#702c29;
  color:#faf3e0;
  transition: 0.2s ease-in;

}

  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar-links ul{
    width: 100%;
    flex-direction: column;
  }

  .navbar-links li{
    text-align: center;
  }

  .navbar-links li a{
    padding: .7rem 1rem;
  }

  .navbar-links.active{
    display: flex;
  }

  .toggle-button.rotate #first{
    background-color: #702c29;
    visibility: hidden;
  }

  .toggle-button.rotate #two{
    transform: rotate(45deg);
    position: relative;
    top: 4.5px;
    /* transition:  0.2s ease-in; */
  }

  .toggle-button.rotate #three{
    transform: rotate(-45deg);
    position: relative;
    top: -4.5px;
    /* transition:  0.2s ease-in; */
  }

  

  
  


}


</style>
