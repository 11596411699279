<template>
<Navbar ref="NavbarCart"/>
<posterPic Title="עגלת קניות"/>


<section class="cart" ref="cart">

<div class="cartContainer">
  <table>
    <thead>
      <tr>
        <th>עגלת קניות ({{ this.cartItems }} פריטים)</th>
        <th>מחיר</th>
      </tr>
    </thead>
  </table>
  <div class="noneProduct" v-if="this.products.length == 0">
    <h2>לא קיים מוצר בסל</h2>
    <br>
    <h6>רוצים להזמין? כנסו <router-link style="text-decoration: none;" to="/menu">לתפריט</router-link></h6>
  </div>
  <div class="ProductContainer" v-for="product in products" :key="product">
    <div class="ProductImg">
      <img :src="require(`@/assets/photo/${product.item.CakeImgMenu}`)">
    </div>
    <div class="ProductInfo">
      <h5>{{product.item.titleCake}}</h5>
      <h6>מחיר : <span>&#8362;</span>{{ product.item.priceCake }}</h6>
      <div class="quantityDiv">
      <i class="bi bi-dash-circle" @click="this.plusAndMinusButtonCart(product.id,'minus')" @mouseenter="addClass" @mouseleave="removeClass"></i>
      <span>{{ product.inCart }}</span>
      <i class="bi bi-plus-circle"  @click="this.plusAndMinusButtonCart(product.id,'plus')" @mouseenter="addClass" @mouseleave="removeClass"></i>
      </div>
      <h6 v-if='product.item.extras != null'>תוספות : {{ product.item.extras }}</h6>
      <h6 v-else>תוספות : ללא תוספות</h6>
    </div>
    <div class="ProductPrice">
      <h4><span>&#8362;</span>{{ product.item.priceCake * product.inCart}}</h4>
    </div>
  </div>
</div>

<div class="PayContainer">
  <div class="TitlePay">
    <h4>סכום ביניים ({{ cartItems }} פריטים) :  <span>&#8362;</span>{{ this.allPrice }}</h4>
    <!-- <h4 v-if="this.productLengthTest >= 1">משלוח : <span>&#8362;</span>{{ this.DeliveryCost }}</h4> -->
  </div>
  <button class="btn btn-primary">למעבר לתשלום (<span>&#8362;</span>{{ this.FinallyPrice }})</button>
  
</div>

</section>

  
<Footer/>
</template>


<script>
import Navbar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import posterPic from '@/components/posterPic.vue'
import CakeContainer from '@/components/CakeContainer.vue'
import NavMenu from '@/components/navMenu.vue'
import axios from 'axios'

export default {
  name: 'Cart',
  components: {
    Navbar,
    Footer,
    posterPic,
    CakeContainer,
    NavMenu
  },
  // props:["CakeImgMenu","titleCake","priceCake","productCountPrice"],
  async created(){
    await this.getMenu()
    this.getSession()
    // console.log(JSON.parse(localStorage.getItem('productsInCart')))
  },
  mounted(){
    window.scrollTo(0,0)
  },
  data(){
    return{
      testt:'',
      allPrice:0,
      FinallyPrice:0,
      DeliveryCost:25,
      cartItems:0,
      products:[],
      cakes:[],
      productLengthTest:0,
      // products:[
      // {
      //   id:1234,productImg:"photo1.jpg",productPrice:"300",productQuantity:"1",name:'עוגת שוקולד מצופה בחמאת בוטנים',productOptions:'אוראו , אקסטרא וניל , MNM'
      // },
      // {
      //   id:321,productImg:"photo2.jpg",productPrice:"200",productQuantity:"1",name:'עוגת שוקולד מצופה בחמאת בוטנים',productOptions:'אוראו , אקסטרא וניל , MNM'
      // },
      // {
      //   id:4321,productImg:"photo3.jpg",productPrice:"350",productQuantity:"1",name:'עוגת שוקולד מצופה בחמאת בוטנים',productOptions:'אוראו , אקסטרא וניל , MNM'
      // },
      // {
      //   id:12345,productImg:"photo4.jpg",productPrice:"300",productQuantity:"1",name:'עוגת שוקולד מצופה בחמאת בוטנים',productOptions:'אוראו , אקסטרא וניל , MNM'
      // },
      
      // ],
    }
  },
  methods: {
    getSession(){

      var storedItems = JSON.parse(sessionStorage.getItem('CartItems'));

      for (let i = 0; i < storedItems.length; i++) {
          var item = this.cakes.find((item) => item._id == storedItems[i].id)
          storedItems[i].item = item
        }
        this.products = storedItems
        this.allPrice = this.checkAllPrice(this.products)
        this.cartItems = storedItems.length
        this.$refs.NavbarCart.SetData()

    },
    async getMenu(){

    await axios.get('/.netlify/functions/GetDataCollectionCakes').then(response => {
        console.log(response.data || []);
        var arr = []
        Object.keys(response.data).forEach(key => arr.push(response.data[key]))
        console.log(Array.isArray(arr))
        this.cakes = arr
    }).catch(error => {
        console.log(error);
    }); 
    },
    checkAllPrice(products){
      return products.reduce((acc, item) =>{
        return acc + (item.item.priceCake * item.inCart)
      },0)
    },
    addClass: function (e) {
      if (e.target.classList.contains("bi-dash-circle")) {
        e.target.classList.replace("bi-dash-circle","bi-dash-circle-fill");
      }else if (e.target.classList.contains("bi-plus-circle")) {
        e.target.classList.replace("bi-plus-circle","bi-plus-circle-fill");
      }
    },
    removeClass: function (e) {
      if (e.target.classList.contains("bi-dash-circle-fill")) {
        e.target.classList.replace("bi-dash-circle-fill","bi-dash-circle");
      }else if (e.target.classList.contains("bi-plus-circle-fill")) {
        e.target.classList.replace("bi-plus-circle-fill","bi-plus-circle");
      }
    },
    async plusAndMinusButtonCart(product,type){
      const SessionData = JSON.parse(sessionStorage.getItem('CartItems'));
      let item = SessionData.find((item) => item._id == product.id)
      let index = SessionData.findIndex((item) => item._id == product.id)
      var ItemDeleted = false
      if(type == 'plus'){
        item.inCart ++
        ItemDeleted = false
      }else if(type == 'minus'){
        if(item.inCart == 1){
          await this.DeleteItem(product)
          ItemDeleted = true
        }else{
          item.inCart --
          ItemDeleted = false
          }
      }
      if(!ItemDeleted){
        sessionStorage.setItem('CartItems', JSON.stringify(SessionData));
        this.getSession();
      }
    },
    DeleteItem(product){
      const SessionData = JSON.parse(sessionStorage.getItem('CartItems'));
      let index = SessionData.findIndex(item => item._id == product.id)
      
      SessionData.splice(index,1)
      sessionStorage.setItem('CartItems', JSON.stringify(SessionData));
      this.getSession();
    },
    },
  }

</script>

<style scoped>

/* *,body{
  background-color: #fce8f1;
} */

.empty{
  height: 400px;
}

/* ========== cart ============ */

.cart{
  border:1px solid none;
  direction: rtl;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  width: 100%;
  padding: 100px 0px;
  color:white;
}

.cartContainer{
  background-color: #702c29;
  position: relative;
  border: 1px solid none;
  padding: 20px;
  width: 60%;
  height: auto;
  border-radius: 25px 25px 25px 25px;
}

table,thead{
  direction: rtl !important;
  text-align: center;
  width: 100%;
}

thead tr {
  border-bottom: 4px solid #a5635f;
}

thead tr th:first-child{
  float: right;
  padding: 0px 50px 15px 0px;
  font-size: 30px;
}

thead tr th:last-child{
  position: relative;
  margin-top: 3%;
  margin-left: 1%;
  float: left;
}

.ProductContainer{
  /* border: 1px solid green; */
  direction: rtl;
  display: flex;
  width: 100%;
  height: 250px;
  border-bottom: 1.5px solid #a5635f;
}

.ProductContainer:last-child{
  border-bottom: none;

}
.ProductImg{
  /* border: 1px solid rgb(0, 13, 255); */
  width: 30%;
  /* height: 70%; */
  height: 90%;
  border-radius: 10px;
  padding-top: 2%;
}

.ProductImg img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.ProductInfo{
  /* border: 1px solid orange; */
  width: 50%;
  height: 100%;
  padding-top: 1%;
  padding-right: 3%;
}

.quantityDiv span{
  padding: 0px 5px 0px 5px;
}
.ProductPrice{
  /* border: 1px solid rgb(255, 0, 195); */
  width: 20%;
  height: 100%;
  text-align: left;
  padding: 20px;
}

.ProductInfo h5, .ProductInfo h6{
  margin-top: 3%;
  /* font-size: 20px; */
}

.ProductInfo h6{
  font-size: 16px;
}

.quantityDiv{
  border: 1px solid none;
  font-size: 21px;
  width: 100%;
  margin-top: 3%;
}

i{
  font-size: 22px;
  cursor: pointer;
/* add fill to button minus and plus */
}

i:hover{
  transition-delay: ease 0.9s;
}

.PayContainer{
  position: sticky;
  top: 0;
  padding: 20px;
  direction: rtl;
  border: 1px solid none;
  width: 30%;
  height: 200px;
  text-align: right;
  border-radius: 25px 25px 25px 25px;
  background-color: #702c29;

}

.PayContainer button{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

.noneProduct{
  direction: rtl;
  border: 1px solid none;
  text-align: center;
  padding: 50px;
}



/* ================ Responsive Design =============*/
@media (max-width: 850px) {
  .ProductImg{
    /* border: 1px solid rgb(0, 13, 255); */
    width: 65%;
    /* height: 70%; */
    height: 60%;
    border-radius: 10px;
    padding-top: 2%;
  }
  .ProductInfo{
  /* border: 1px solid orange; */
    width: 80%;
    height: 100%;
    padding-top: 1%;
    padding-right: 3%;
  }
  .cart{
    flex-direction: column;
    align-items: center;
  }
  .cartContainer{
    width: 95%;
    height: auto;
    border-radius: 25px 25px 0px 0px ;
  }
  .ProductContainer{
    padding:20px;
  }
  .PayContainer{
    position: relative;
    top: -10px;
    width: 95%;
    border-radius: 0px 0px 25px 25px;
    text-align: center;
    min-height: 150px;
    border-top: 1.7px solid pink;
  }
  .PayContainer .TitlePay{
    border:1px solid none;
    padding: 15px;
  }
  .PayContainer button{
    border-radius: 0px 0px 25px 25px;
  }

}



</style>




