<template>
  <div id="myBtnContainer">
    <div class="TitleDiv" @click='this.OpenMenuSort()'>
      <h2>סינון</h2>
      <button class="btn btn-no-focus gearButton" ><i class="bi bi-gear gearCss"></i></button>
    </div>
    <div class="OverFlow">
      <div class="AllMenu">
      <div v-if="!this.EditMode" class="btnOption optionsbtn clickedNav" data-color="הכל" @click="filterSelection('הכל');">הכל</div>
    <div v-for="(option,index) in this.Options" :key="option" class="btnOption optionsbtn" :data-color="option.HebrewName" @click="filterSelection(option.HebrewName);">
      <span v-if="!this.EditMode">{{option.HebrewName}}</span>
      <div class="flex-inputs" v-else>
        <span ><input type="text" :id="index" v-model="this.Options[index].HebrewName"></span>
        <button class="btn btn-no-focus btn-danger" @click="this.removeItem(index)"><i class="bi bi-trash"></i></button>
      </div>

    </div>
   
    <div class="btnOption Upload-Item" v-if="this.EditMode"><button class="btn btn-no-focus btn-info" @click="this.addItem()"><i class="bi bi-plus"></i></button></div>
    <!-- <button @click="this.UpdateDb()" class="btn btn-success"><i class="bi bi-check2"></i></button> -->
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: ['Options','EditMode'],
  data(){
    return{
    }
  },
  mounted(){
    // addEventListener.this.filterSelection("all")
    // console.log(this.EditMode)
  },
  methods: {
    addItem() {
      this.Options.push({ HebrewName: ''});
    },
    removeItem(index) {
      this.Options.splice(index, 1);
    },
    UpdateDb(){
      this.$emit('Update-DB')
    },
    OpenMenuSort(event) {
      const AllMenu = document.querySelector('.AllMenu');
      const gearButton = document.querySelector('.gearButton');

      // Determine the current state
      const isActive = AllMenu.classList.contains('active');
      const isClosed = AllMenu.classList.contains('close');

      // Toggle classes based on current state
      if (isActive) {
        gearButton.classList.remove('rotate');
        gearButton.classList.add('rotateClose');
        AllMenu.classList.add('close');
        setTimeout(() => {
          AllMenu.classList.remove('active');
        }, 600);
      } else if (isClosed) {
        gearButton.classList.remove('rotateClose');
        AllMenu.classList.remove('close');
        AllMenu.classList.add('active');
        gearButton.classList.toggle('rotate');
      } else {
        AllMenu.classList.add('active');
        gearButton.classList.toggle('rotate');
      }

      },
    filterSelection(c){
      if(this.EditMode == false){
        const coverColors = document.querySelectorAll('.optionsbtn');

        coverColors.forEach(item => {
          const color = item.getAttribute('data-color');
          if (color === c) {
            item.classList.add('clickedNav');
          } else {
            item.classList.remove('clickedNav');
          }
        });   

        this.$emit('Tag-Filter',c)
      }
      
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

*{
padding : 0;
margin: 0; 
}

.TitleDiv{
  position: relative;
  border-bottom: 1px solid white;
  width:100%;
  padding: 20px 0px;
  margin: 0px 0px 20px 0px;
  cursor: pointer;
  z-index: 2000;
}

.TitleDiv button{
  position: absolute;
  right:0%;
  bottom: 0;
}

/* Style the buttons */
.btnOption {
  /* border-bottom: 1px solid rgb(252, 177, 190); */
  /* border-bottom: 1px solid rgb(126, 182, 255); */
  border-bottom: 1px solid #ac4e49;
  outline: none;
  padding: 16px 16px;
  cursor: pointer;
  font-size: 20px;
  width: 100%;
  transition: 0.2s ease-out;
  border-radius: 0.375rem;
  color: white;
}

.btnOption:hover{
  background-color: #ac4e49;
  transition: 0.2s ease-in;
}

#myBtnContainer{
  position: relative;
  background-color: #702c29;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
  margin-top: 10%;
  float: right;
  text-align: center;
  z-index: 100000;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 12px; */
}

#myBtnContainer h2{
  text-align: center;
  color: white;
}

hr{
  position: relative;
  top: 10px;
  padding: 8px;
}

.clickedNav{
  border:1px solid none !important;
  background-color: #ac4e49 !important;
  color: white !important;
}

.optionsbtn input{
  width: 90%;
}

.Upload-Item button i{
  color: white;
  font-size: 1.7em;
  padding: 0px 8px;
}

.flex-inputs{
  display: flex;
  flex-direction: column;
}

.gearButton{
    display: none;
  }


.OverFlow{
  overflow: hidden;
  width: 100%;
  background: transparent;
  border-radius: 0px 0px 15px 15px;
}


@media (max-width: 850px) {
  
  #myBtnContainer{ 
    border: 1px solid none;
    position: relative;
    width: 100%;
    direction: rtl;
  }

  .btnOption,.btn-check {
    width: fit-content;
    font-size: 100%;
    margin-right: 2%;
  }

  .gearButton{
    display: block;
    outline: none;
    border: none;
  }

  .AllMenu{
    flex-wrap: wrap;
    background: #893834;
    display: none;
    transition: ease 0.2s;
    padding: 10px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    border-radius: 0px 0px 15px 15px; 
     
  }

  .active{
    display: flex;
    justify-content: center;
    transition: ease 0.2s;
    animation: showingMenu .3s ease-in;
  }

  .close{
    animation: CloseingMenu .6s ease-in;  
  }

  .rotate{
    rotate: 90deg ;
    transition: ease .4s;
  }

  .rotateClose{
    rotate: -90deg ;
    transition: ease .4s;
  }

  .btnOption:last-child{
    border-bottom: 1px solid rgb(170, 206, 254);

  }

  i.gearCss{
    color:white;
  }

  hr{
    position: relative;
    width: 97%;
    padding: 8px;
  }

  
}

@keyframes showingMenu{
  from{
    transform: translateY(-100px);
  }
  to{
    transform: translateY(0px);
  }
}

@keyframes CloseingMenu{
  from{
    transform: translateY(0px);
  }
  to{
    transform: translateY(-150px);
  }

}


/* @media (max-width: 469px) {
  
  #myBtnContainer{ 
    width: 100%;
    right: 0;
    padding-right: 5%;
  }


  
} */


</style>
