import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/view/Home.vue';
import Menu from '@/view/Menu.vue';
import Cart from '@/view/CartTwo.vue';
import Upgrade from '@/view/Upgrade.vue';



const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta:{
			title:"עמוד הבית",
			Upgrade:false,
		},
		
	},
	{
		path: '/gallery',
		name: 'gallery',
		component: Home,
		meta:{
			title:"גלריה",
			Upgrade:true,

		},
	},
	{
		path: '/WorkShops',
		name: 'WorkShops',
		component: Home,
		meta:{
			title:"סדנאות",
			Upgrade:true,

		},
	},
	{
		path: '/contact',
		name: 'contact',
		component: Home,
		meta:{
			title:"contact",
			Upgrade:false,
		},
	},
	{
		path: '/menu',
		name: 'menu',
		component: Menu,
		meta:{
			title:"תפריט",
			Upgrade:false,
		},
	},
	{
		path: '/cart',
		name: 'cart',
		component: Cart,
		meta:{
			title:"סל קניות",
			Upgrade:false,
		},
	},
	{
		path: '/Upgrade',
		name: 'Upgrade',
		component: Upgrade,
		meta:{
			title:"אתר בשיפוצים",
		},
	},
	// {
	// 	path: `/search=":Input"`,
	// 	// path: `/search`,
	// 	name: 'SearchTemplate',
	// 	component: SearchTemplate,
	// 	meta:{
	// 		title:"חיפוש",
	// 	},
	// },
	
];

let previousRoute = null;

const router = createRouter({
	history: createWebHistory(),
	routes,
	
});



router.beforeEach((to, from, next)=>{
	document.title = `${to.meta.title}`;
	previousRoute = from; // Store previous route
	// if(from.Upgarde)
	if(to.meta.Upgrade == true){
		next({name:'Upgrade' , params:{name:to.meta.title}})
	}else{
		next();
	}
});



export { router, previousRoute };
export default router;


